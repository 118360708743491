/**
 * Checks if the provided array is empty.
 * @param array - The array to check.
 * @returns `true` if the array is empty, `false` otherwise.
 */
export function Empty(array: Array<any>): boolean {
  return array.length === 0;
}

export function TypeOfItems(array: Array<any>): string {
  if (!array.length) {
    return 'unknown';
  }

  const firstItem = array[0];

  if (typeof firstItem === 'string') {
    return 'string';
  } else if (typeof firstItem === 'number') {
    return 'number';
  } else if (typeof firstItem === 'object' && firstItem !== null) {
    return 'object';
  } else {
    return 'unknown';
  }
}
